.nomeSection{
    display: flex;
    align-items: center;
    font-family: 'Stint Ultra Condensed', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    background-color: #deb503;
    padding: 10px;
}

.nomeItem{
    font-family: "Open Sans", serif;
    /* background-color: #fd0a07; */
    width: 55vw;
    max-width: 55vw;
    font-size: large;
    font-weight: 700;
    
}

.descricao{
    text-align: start;
    width: 60vw;
    font-size: 10px;
}

.item{
    border-bottom:.5px solid rgba(45, 45, 45, 0.100) ;
}
.itemContent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 15px;
    height: 10vh;
    transition: all 0.8s ease;
}

.item:hover .itemContent{
    background-color: rgba(0, 0, 0, 0.200);
    transform: scale(1.05);
}

.nomeItemDescricao{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

  

.preco{
    font-family: 'Open Sans', serif;
    font-size: xx-large;
    font-weight: bold;
    color: #fb1512;

}
.indisponivel{
   text-align: center;
    font-family: 'Open Sans', serif;
    font-size: medium;
    font-weight: bold;
    color: #fb1512;
}


.precoConteiner{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sifrao{
    font-weight: bold;
    font-size: smaller;
    color: #fb1512;
}
.nulo{
    display: none;
}

.up{
    width: 20px;
    transform: scaleY(-1);
    position: absolute;
    left: 90vw;

}


.whatsapp{
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 60px; /* Fixa o menu no topo */
    right: 5vw;
    z-index: 10; 
    cursor: pointer;
    /* box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5) ; */
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
  }


  .selectOption{
    font-family: 'Stint Ultra Condensed', serif;
    width: 40vw;
    height: 40px;
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    left: 40vw;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ffffff ;
    background-color: #deb503;
    color: black;
  }