*{
  margin: 0;
}


html{
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
}

