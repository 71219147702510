.logo{
    width: 20vw;
    height: 20vw;
    /* background-color: aliceblue; */
     /* box-shadow: 10px 10px 15px 50px rgb(255, 255, 255) ; */
    filter: drop-shadow(0px 0px 10px  rgb(255, 255, 255));
    /* border-radius: 50%; */
    
}



.containerHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Stint Ultra Condensed', serif;
    font-weight: lighter;
    font-size: x-large;
    color: #deb503;
    

}




header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: sticky;
    top: 0; /* Fixa o menu no topo */
    background-color: #de0303;
    color: white;
    padding: px;
    text-align: center;
    z-index: 10; /* Garante que o menu esteja acima de outros elementos */
    transition: box-shadow 1s ease-out;
}

header.scrolled{
    box-shadow: 0px 0px 60px 3px rgba(0, 0, 0, 0.8)  !important;
  }


